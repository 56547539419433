import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext();
export const UserProvider = ({ children }) => {
    const navigate = useNavigate()
    const [auth, setAuth] = useState(null);
    const [Theme, setTheme] = useState("theme-3");
    const [ThemeColor, setThemeColor] = useState("theme-color-1");
    const [MiniSidebar, setMiniSidebar] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [questionYubObj, setQuestionYubObj] = useState();
    const [imageCoordinate, setImageCoordinate] = useState({});
    const [breadcrumbs, setBreadcrumbs] = useState({});
    const [csvData, setCsvData] = useState([]);
    const [buildFilter, setBuildFilter] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: "bbl", direction: "dsc" });
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        let authLocal = localStorage.getItem("token");
        let isAuth = authLocal ? true : false;
        if (!isAuth) {
            navigate('/login')
        }
        setAuth(isAuth);
    }, []);
    return (
        <UserContext.Provider
            value={{
                ThemeColor,
                Theme,
                auth,
                MiniSidebar,
                setThemeColor,
                setTheme,
                setAuth,
                setMiniSidebar,
                userInfo,
                setUserInfo,
                questionYubObj,
                setQuestionYubObj,
                imageCoordinate,
                setImageCoordinate,
                breadcrumbs,
                setBreadcrumbs,
                csvData, setCsvData,
                buildFilter, setBuildFilter,
                sortStatus, setSortStatus,
                page, setPage,
                perPage, setPerPage
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
